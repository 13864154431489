import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "react-query";

import theme from "@/theme";
import EchoProvider from "@providers/EchoProvider";
import "@styles/main.scss";
import { DevTools as JotaiDevTools } from "jotai-devtools";

import { ChakraBaseProvider } from "@chakra-ui/react";

import { logAppError } from "@utils/logAppError";

import { ErrorBoundaryFBContainer } from "@containers/Misc/ErrorBoundaryFBContainer";

import CheckoutContextProvider from "./context/CheckoutContext";

interface PropTypes {
    children: React.ReactNode;
}

const queryClient = new QueryClient();

const AppProvider = ({ children }: PropTypes) => {
    return (
        <ChakraBaseProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <CheckoutContextProvider>
                    <ErrorBoundary
                        FallbackComponent={ErrorBoundaryFBContainer}
                        onError={logAppError}
                    >
                        <EchoProvider>{children}</EchoProvider>
                    </ErrorBoundary>
                    <JotaiDevTools />
                </CheckoutContextProvider>
            </QueryClientProvider>
        </ChakraBaseProvider>
    );
};

export default AppProvider;
