import { createContext, useState } from "react";

import { ProductFamilyInterface } from "@/ts/interfaces/Checkout/ProductFamilyInterface";

interface CheckoutContextType {
    productFamilies: ProductFamilyInterface[] | null;
    userSelectedCountry: string;
    userSelectedState: string;
    isProductFamiliesLoading: boolean;
    userSelectedLang: string;
    currency: "AUD" | "USD";

    onUpdateProductFamilies: (
        productFamilies: ProductFamilyInterface[]
    ) => void;
    onUpdateUserSelectedCountry: (country: string) => void;
    onUpdateUserSelectedState: (state: string) => void;
    onUpdateIsProductFamiliesLoading: (isLoading: boolean) => void;
    onUpdateUserSelectedLang: (lang: string) => void;
    onUpdateCurrency: (currency: "USD" | "AUD") => void;

    clearCheckoutContext: () => void;
}

interface PropTypes {
    children: React.ReactNode;
}

export const CheckoutContext = createContext<CheckoutContextType>({
    productFamilies: null,
    userSelectedCountry: "",
    userSelectedState: "",
    currency: "AUD",
    userSelectedLang: "",
    isProductFamiliesLoading: false,

    onUpdateProductFamilies: () => {},
    onUpdateUserSelectedCountry: () => {},
    onUpdateUserSelectedState: () => {},
    onUpdateIsProductFamiliesLoading: () => {},
    onUpdateCurrency: () => {},
    onUpdateUserSelectedLang: () => {},

    clearCheckoutContext: () => {}
});

// TODO: Improve hardcorded check for contries don't need state selections
const CheckoutContextProvider = ({ children }: PropTypes) => {
    const searchParams = new URLSearchParams(window.location.search);

    const selectedCountry = searchParams.get("selectedCountry") || "";
    const selectedState = searchParams.get("selectedState") || "";
    const selectedLang = searchParams.get("selectedLang") || "";

    const [productFamilies, setProductFamilies] = useState<
        ProductFamilyInterface[]
    >(() => {
        const savedValue = sessionStorage.getItem("productFamilies");
        return savedValue ? JSON.parse(savedValue) : [];
    });
    const [userSelectedCountry, setUserSelectedCountry] = useState(() => {
        if (selectedCountry) return selectedCountry.toUpperCase();
        const savedValue = sessionStorage.getItem("userSelectedCountry");
        return savedValue !== null ? savedValue : "AU";
    });
    const [userSelectedState, setUserSelectedState] = useState(() => {
        if (selectedState) return selectedState.toUpperCase();
        const savedValue = sessionStorage.getItem("userSelectedState");
        return savedValue !== null ? savedValue : "";
    });
    const [isProductFamiliesLoading, setIsProductFamiliesLoading] =
        useState(false);

    const [currency, setCurrency] = useState(() => {
        const savedValue = sessionStorage.getItem("currency");
        return savedValue !== null ? (savedValue as "AUD" | "USD") : "AUD";
    });

    const [userSelectedLang, setUserSelectedLang] = useState(() => {
        if (selectedLang) return selectedLang;
        const savedValue = sessionStorage.getItem("userSelectedLang");
        return savedValue !== null ? savedValue : "en";
    });

    const onUpdateProductFamilies = (
        productFamilies: ProductFamilyInterface[]
    ) => {
        setProductFamilies(productFamilies);
        sessionStorage.setItem(
            "productFamilies",
            JSON.stringify(productFamilies)
        );
    };
    const onUpdateUserSelectedCountry = (country: string) => {
        setUserSelectedCountry(country);
        sessionStorage.setItem("userSelectedCountry", country);
    };
    const onUpdateUserSelectedState = (state: string) => {
        setUserSelectedState(state);
        sessionStorage.setItem("userSelectedState", state);
    };
    const onUpdateIsProductFamiliesLoading = (isLoading: boolean) => {
        setIsProductFamiliesLoading(isLoading);
    };
    const onUpdateUserSelectedLang = (lang: string) => {
        setUserSelectedLang(lang);
        sessionStorage.setItem("userSelectedLang", lang);
    };
    const onUpdateCurrency = (currency: "AUD" | "USD") => {
        setCurrency(currency);
        sessionStorage.setItem("currency", currency);
    };

    const clearCheckoutContext = () => {
        setProductFamilies([]);
        setUserSelectedCountry("AU");
        setUserSelectedState("");
        setCurrency("AUD");
        setUserSelectedLang("en");

        sessionStorage.removeItem("productFamilies");
        sessionStorage.removeItem("userSelectedCountry");
        sessionStorage.removeItem("userSelectedState");
        sessionStorage.removeItem("userSelectedLang");
        sessionStorage.removeItem("currency");
    };

    return (
        <CheckoutContext.Provider
            value={{
                productFamilies,
                userSelectedCountry,
                userSelectedState,
                isProductFamiliesLoading,
                userSelectedLang,
                currency,
                onUpdateProductFamilies,
                onUpdateUserSelectedCountry,
                onUpdateUserSelectedState,
                onUpdateIsProductFamiliesLoading,
                onUpdateUserSelectedLang,
                onUpdateCurrency,
                clearCheckoutContext
            }}
        >
            {children}
        </CheckoutContext.Provider>
    );
};

export default CheckoutContextProvider;
